import React, {useState, useEffect, useRef}  from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components'
import {GatsbyImage} from "gatsby-plugin-image"
import parse from "html-react-parser"
import SocialShare from '../components/global/SocialShare'
import ArticleBuilder from '../components/blogArticle/ArticleBuilder'
import Arrow from '../assets/slider-arrow.inline.svg'
import Seo from "../components/seo"
import {BlogArticleStyle} from '../styles/blog-article-styles'
import RellaxWrapper from 'react-rellax-wrapper'

// We're using Gutenberg so we need the block styles

const PostHeader = styled.header`
    .bg_wrapper{
        height:calc(100% - ${props => props.heroHeight});
    }
`

const ArticleContent = styled.section`

`


const wrapperStyles = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: '0',
  left: '0',
}


const CaseStudyPostTemplate = ({ data: { previous, next, post }, pageContext, data}) => {
  const {slug } = pageContext;
  const heroRef = useRef(null);
  const [heroSize, setHeroSize] = useState(0);
    const seo = data.seo.seo;
  
  const featuredImage = {
    fluid: post.acf_caseStudyFields?.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.acf_caseStudyFields?.backgroundImage?.node?.alt || ``,
  }



  const calcHeroSize = () => {
    if(heroRef.current){
      setHeroSize(heroRef.current.offsetHeight)
      
      window.addEventListener('resize',calcHeroSize)
    }
  }

  useEffect(calcHeroSize);
  

  return (
    
    <div>
       <BlogArticleStyle />
          <Seo title={seo.title} description={seo.metaDesc} />
          <article
            className="blog-post"
            itemScope
            itemType="http://schema.org/Article"
          >

            <PostHeader className="blog-article--postHeader" heroHeight={heroSize/2+'px'}>
              <nav className="blog-post-nav">
                <ul>
                  <li>
                    {previous && (
                      <Link to={slug+'/'+previous.slug} rel="prev">
                        <Arrow />
                      </Link>
                    )}
                  </li>

                  <li>
                    {next && (
                      <Link to={slug+'/'+next.slug} rel="next">
                        <Arrow />
                      </Link>
                    )}
                  </li>
                </ul>
              </nav>
              <div className="wrapper">
              <h1 className="heading--l">Case Study: {parse(post.title)}</h1>

              <div className="author-date-share">
                <div className="share">
                  <SocialShare />
                </div>
              </div>

               {/* if we have a featured image for this post let's display it */}
               {featuredImage?.fluid && (
                <div className="heroImage" ref={heroRef}><GatsbyImage image={featuredImage?.fluid} /></div>
              )}
              </div>
              <div className="bg_wrapper">
                <RellaxWrapper  mobile={-1.25} tablet={-2}  desktop={-5.25} breakpoints={[500, 1024, 1201]}  style={wrapperStyles}>
                  <div className="bg"></div>
                </RellaxWrapper>
              </div>
            </PostHeader>
            

            <ArticleContent className="article-content blog-article--articleContent">
              <div className="wrapper wrapper-flex">
                <aside className="sidebar">
                  <div className="published-date">
                    <span>Published: </span>
                    <span>{post.date}</span>
                  </div>
                </aside>
                <div className="main-content">
                  {
                    post.acf_articleBuilder.articleBuilder ?
                      <ArticleBuilder data={post.acf_articleBuilder.articleBuilder} />
                    :""
                  }
                  
                  <footer>
                    
                      <div className="footer-inner">
                        <div className="share">
                          <SocialShare />
                        </div>
                      </div>
                    
                  </footer>
                  </div>
                </div>
        
            </ArticleContent>
                
          </article>
        </div> 
   
  )
}

export default CaseStudyPostTemplate

export const pageQuery = graphql`
  query CaseStudiesById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {

    
    seo: wpCaseStudy(id: { eq: $id })  {
      seo {
        metaDesc
        title
        metaRobotsNofollow
        metaRobotsNoindex
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            id
          }
        }
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        twitterTitle
        twitterDescription
      }
    }
    
    #================================
    # LATEST ARTICLES SECTION 
    #================================
    latest: allWpPost(limit: 10) {
      nodes {
        title
        excerpt
        slug
        categories {
          nodes {
            slug
          }
        }
        acf_customFeaturedImage {
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allAdverts: allWpAdvert{
      edges {
        node {
          id
          title
          acf_adverts {
            adColour
            adPageLink {
              ... on WpListing {
                id
                slug
                title
              }
            }
            adPageLinkExternal
            advertImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            advertImageMobile {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            internalOrExternalLink
          }
        }
      }
    }

    # selecting the current post by id
    post: wpCaseStudy(id: { eq: $id }) {
      id
      title
      date(formatString: "D MMMM YYYY")
      acf_caseStudyFields {
        backgroundImage {
          localFile{
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        logo {
          localFile{
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
     
    
      acf_articleBuilder {
        articleBuilder {
          ... on WpCase_study_AcfArticlebuilder_ArticleBuilder_Image {
            imageFocalPoint
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            fieldGroupName
          }
          ... on WpCase_study_AcfArticlebuilder_ArticleBuilder_Video {
            fieldGroupName
            video
          }
          ... on WpCase_study_AcfArticlebuilder_ArticleBuilder_Gallery {
            fieldGroupName
            gallery {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpCase_study_AcfArticlebuilder_ArticleBuilder_Text {
            fieldGroupName
            text
          }
        }
      }
    
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      slug
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      slug
      title
    }
  }
`
